import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './editRoutine.css';
import Base from '../base';
import Input from '../components/input';
import Button from '../components/button';
import DatePicker, { registerLocale } from 'react-datepicker';
import TimePicker from 'react-time-picker';
import { ko } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import axios from 'axios';

registerLocale('ko', ko);

const EditRoutine = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [routineTitle, setRoutineTitle] = useState(''); // 기본값으로 빈 문자열 설정
    const [startDate, setStartDate] = useState(null); // 기본값으로 null 설정
    const [endDate, setEndDate] = useState(null); // 기본값으로 null 설정
    const [time, setTime] = useState(''); // 기본값으로 빈 문자열 설정
    const [activeDays, setActiveDays] = useState([]); // 기본값으로 빈 배열 설정
    const [errorMessage, setErrorMessage] = useState('');
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    useEffect(() => {
        const fetchRoutine = async () => {
            try {
                const response = await axios.get(`${API_URL}/routine/${id}`, { withCredentials: true });
                const routine = response.data;
                setRoutineTitle(routine.title || ''); // 데이터를 불러오지 못했을 경우 빈 문자열
                setStartDate(routine.startDate ? new Date(routine.startDate) : null); // 날짜가 없는 경우 null
                setEndDate(routine.endDate ? new Date(routine.endDate) : null); // 날짜가 없는 경우 null
                setTime(routine.time || ''); // 시간을 불러오지 못했을 경우 빈 문자열
                setActiveDays(routine.activeDays ? routine.activeDays.split(', ') : []); // 요일이 없는 경우 빈 배열
            } catch (error) {
                console.error('Error fetching routine:', error);
                setErrorMessage('루틴 데이터를 가져오는 중 오류가 발생했습니다.');
            }
        };

        fetchRoutine();
    }, [id]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleDayClick = (day) => {
        setActiveDays((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };

    const handleSubmit = async () => {
        if (!routineTitle || !startDate || !endDate || !time || activeDays.length === 0) {
            setErrorMessage('모든 필드를 작성해주세요.');
            return;
        }

        if (endDate < startDate) {
            setErrorMessage('종료 날짜는 시작 날짜보다 이후여야 합니다.');
            return;
        }

        const updatedRoutine = {
            title: routineTitle,
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
            time,
            activeDays: activeDays.join(', '),
        };

        try {
            await axios.put(`${API_URL}/routine/update/${id}`, updatedRoutine, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            });
            navigate('/routine');
        } catch (error) {
            console.error('Error updating routine:', error);
            setErrorMessage('루틴 수정 중 오류가 발생했습니다.');
        }
    };

    const openDeleteConfirm = () => {
        setIsDeleteConfirmOpen(true);
    };

    const closeDeleteConfirm = () => {
        setIsDeleteConfirmOpen(false);
    };

    const deleteRoutine = async () => {
        try {
            await axios.delete(`${API_URL}/routine/delete/${id}`, {
                withCredentials: true,
            });
            navigate('/routine');
        } catch (error) {
            console.error('Error deleting routine:', error);
            setErrorMessage('루틴 삭제 중 오류가 발생했습니다.');
        }
    };

    return (
        <div className="editRoutine-container">
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <div className="editRoutine-content">
                <h1 className="editRoutine-title">ROUTINE</h1>
                <h2 className="editRoutine-subtitle">루틴 수정</h2>
                <div className="editRoutine-inputs">
                    <Input
                        type="text"
                        value={routineTitle}
                        onChange={(e) => setRoutineTitle(e.target.value)}
                        placeholder="루틴 제목을 입력하세요"
                    />
                    <DatePicker
                        locale={ko}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy년 MM월 dd일"
                        className="custom-datepicker"
                    />
                    <DatePicker
                        locale={ko}
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy년 MM월 dd일"
                        className="custom-datepicker"
                    />
                    <p>루틴 시작 시간</p>
                    <TimePicker
                        onChange={setTime}
                        value={time}
                        disableClock={true}
                        className="custom-timepicker"
                    />
                    <p>루틴을 반복할 요일을 선택하세요</p>
                    <div className="days">
                        {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
                            <span
                                key={day}
                                className={activeDays.includes(day) ? 'active' : ''}
                                onClick={() => handleDayClick(day)}
                            >
                                {day}
                            </span>
                        ))}
                    </div>
                    <Button className="editRoutine-button" onClick={handleSubmit} text="수정 완료"> </Button>
                    <Button className="editRoutine-button" onClick={openDeleteConfirm} text="루틴 삭제"> </Button>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>

            {isDeleteConfirmOpen && (
              <>
                <div className="delete-confirm-overlay" onClick={closeDeleteConfirm}></div>
                <div className="delete-confirm">
                    <p className="delete-confirm-text">루틴을 삭제하시겠습니까?</p>
                    <div className="delete-confirm-buttons">
                        <button className="modal-button" onClick={closeDeleteConfirm}>취소</button>
                        <button className="modal-button" onClick={deleteRoutine}>삭제</button>
                    </div>
                </div>
            </>
         )}
      </div>
        
    );
};

export default EditRoutine;