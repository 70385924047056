import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import './my.css';
import Base from '../base';
import YearMonthDropdown from './yearMonthDropdown';

Chart.register(...registerables);

const My = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [menuOpen, setMenuOpen] = useState(false);
    const [barData, setBarData] = useState(null);
    const [totalTodos, setTotalTodos] = useState(0);
    const [monthlyCompletedTodos, setMonthlyCompletedTodos] = useState(0); // 월별 완료된 투두 개수
    const [cumulativeCompletedTodos, setCumulativeCompletedTodos] = useState(0); // 누적 완료된 투두 개수    const [level, setLevel] = useState(''); // 사용자 레벨
    const [todosToNextLevel, setTodosToNextLevel] = useState(0); // 다음 등급까지 남은 갯수
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [level, setLevel] = useState('');

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [nickname, setNickname] = useState('USER'); // 사용자 닉네임 상태 추가

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // 사용자 닉네임 받아오기
    useEffect(() => {
        const fetchUserBadgeInfo = async () => {
            try {
                const response = await axios.get(`${API_URL}/my/badge`, { withCredentials: true });               
                 if (response.data) {
                    setNickname(response.data.nickname);
                    setLevel(response.data.badgeLevel); // 사용자 레벨
                    setCumulativeCompletedTodos(response.data.completedTodoCount); // 누적된 완료 투두 개수
                    setTodosToNextLevel(response.data.todosUntilNextLevel); // 다음 등급까지 남은 갯수
                }
            } catch (error) {
                console.error('Error fetching user badge info:', error);
            }
    };

    fetchUserBadgeInfo();
    }, []);

    // 메달 이미지 레벨에 따라 결정
    const getBadgeImage = (badgeLevel) => {
        switch (true) {
          case badgeLevel.includes("Bronze"):
            return '/img/bronze-medal.png';
          case badgeLevel.includes("Silver"):
            return '/img/silver-medal.png';
          case badgeLevel.includes("Gold"):
            return '/img/gold-medal.png';
          case badgeLevel.includes("Diamond"):
            return '/img/diamond-medal.png';
          default:
            return '/img/default-medal.png'; // 기본 이미지
        }
    };

    const fetchTodoStats = async (selectedYear, selectedMonth) => {
        try {
            const response = await axios.get(`${API_URL}/my/todo/${selectedYear}/${selectedMonth}`, { withCredentials: true });
            const data = response.data;

            if (!data || typeof data !== 'object') {
                throw new Error('Invalid response data');
            }

            const categoryStats = data;

            const completedCategoryStats = Object.entries(categoryStats)
                .filter(([category, stats]) => stats.completed > 0)
                .reduce((acc, [category, stats]) => {
                    acc[category] = stats.completed;
                    return acc;
                }, {});

            const barChartData = {
                labels: Object.keys(completedCategoryStats),
                datasets: [
                    {
                        label: 'Completed To Do',
                        data: Object.values(completedCategoryStats),
                        backgroundColor: ['#007bff', '#6495ed', '#87cefa', '#add8e6'],
                    },
                ],
            };

            const total = Object.values(categoryStats).reduce((sum, category) => sum + category.total, 0);
            const completed = Object.values(categoryStats).reduce((sum, category) => sum + category.completed, 0);

            setBarData(barChartData);
            setTotalTodos(total);
            setMonthlyCompletedTodos(completed); // 월별 완료된 투두 개수
        } catch (error) {
            console.error('Error fetching todo stats:', error);
        }
    };

    useEffect(() => {
        fetchTodoStats(year, month);
    }, [year, month]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const barOptions = {
        maintainAspectRatio: true,
        scales: {
            x: {
                type: 'category',
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        return context.dataset.data[context.dataIndex];
                    },
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: '#000000',
            },
        },
        responsive: true,
    };

    return (
        <div className="home-content">
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <div className="ranking-container">
                <div className="user-info">
                    <h2>{nickname}</h2>
                    <p>완료한 TO DO 개수: {cumulativeCompletedTodos}</p>
                    <p>다음 등급까지 남은 개수: {todosToNextLevel}</p>
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{ width: `${(cumulativeCompletedTodos / (cumulativeCompletedTodos + todosToNextLevel)) * 100}%` }}
                        ></div>
                    </div>
                </div>
                <div className="badge-info">
                    <img src={getBadgeImage(level)} alt="badge" />
                    <p>{level}</p>
                </div>
            </div>
            <br/><br/>
            <div className="chart-container">
                <YearMonthDropdown
                    year={year}
                    month={month}
                    onYearChange={setYear}
                    onMonthChange={setMonth}
                />
                <div className="bar-chart">
                    <h3>TO DO</h3>
                    {barData ? <Bar data={barData} options={barOptions} width={280} height={200} /> : <p>Loading...</p>}
                    <p><strong>Total {monthlyCompletedTodos}/{totalTodos}</strong></p>
                </div>
                <br /><br /><br /><br /><br />
            </div>
        </div>
    );
}

export default My;
