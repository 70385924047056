import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import './calendar.css';

const CustomCalendar = ({ selectedDate, setSelectedDate }) => {
    const [viewDate, setViewDate] = useState(new Date());
    const [selectedDay, setSelectedDay] = useState(null);

    useEffect(() => {
        setViewDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1));
    }, [selectedDate]);

    const handlePrevMonth = () => {
        setViewDate(prevDate => {
            const newDate = new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1);
            return newDate;
        });
    };

    const handleNextMonth = () => {
        setViewDate(prevDate => {
            const newDate = new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1);
            return newDate;
        });
    };

    const handleDayClick = (date) => {
        // 날짜를 KST로 변환
        const selectedDateUTC = new Date(date.getTime() + (9 * 60 * 60 * 1000)); // KST로 변환
        console.log('Selected date (UTC):', selectedDateUTC.toISOString()); // 콘솔에 출력
    
        if (date.getMonth() < viewDate.getMonth()) {
            setViewDate(new Date(date.getFullYear(), date.getMonth(), 1));
        } else {
            setSelectedDay(date);
            setSelectedDate(selectedDateUTC); // UTC로 설정
            setViewDate(new Date(date.getFullYear(), date.getMonth(), 1));
        }
    };
    
    
    
    

    const isSameDay = (date1, date2) => {
        return date1 && date2 && date1.getDate() === date2.getDate() &&
               date1.getMonth() === date2.getMonth() &&
               date1.getFullYear() === date2.getFullYear();
    };

    const isSameMonth = (date1, date2) => {
        return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
    };

    const tileClassName = ({ date: tileDate, view }) => {
        if (view === 'month') {
            let className = isSameMonth(tileDate, viewDate) ? 'day' : 'day other-month';
            if (isSameDay(tileDate, selectedDay)) {
                className += ' selected-day';
            }
            if (isSameDay(tileDate, new Date())) {
                className += ' today';
            }
            return className;
        }
        return '';
    };

    const formatMonthYear = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}.${month}`;
    };

    return (
        <div className="calendar">
            <div className="calendar-header">
                <button className="prev-button" onClick={handlePrevMonth}>‹</button>
                <span className="month-year">{formatMonthYear(viewDate)}</span>  {}
                <button className="next-button" onClick={handleNextMonth}>›</button>
            </div>
            <Calendar
                activeStartDate={viewDate}
                value={selectedDay || viewDate}
                onClickDay={handleDayClick}
                tileClassName={tileClassName}
                formatDay={(locale, date) => date.getDate()}
                onActiveStartDateChange={({ activeStartDate }) => setViewDate(activeStartDate)}
            />
        </div>
    );
};

export default CustomCalendar;
