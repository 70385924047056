import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './test.css';
import SignUp from './join/signUp';
import Login from './join/login';
import SignUpEmail from './join/signUpEmail';
import Nickname from './hamburger/nickname';
import Password from './hamburger/password';
import Delete from './hamburger/delete';
import Logout from './hamburger/logout';
import QA from './hamburger/qna';
import Base from './base';
import Home from './home/home';
import Memo from './memo/memo';
import Routine from './routine/routine';
import NewRoutine from './routine/newRoutine';
import AllRoutines from './routine/allRoutines'; 
import EditRoutine from './routine/editRoutine'; 
import CategoryAdd from './home/category-add';
import TodoAdd from './home/todo-add';
import My from './my/my';
import { requestPermissionAndGetToken } from "./firebase";


const App = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        requestPermissionAndGetToken().then(token => {
            if (token) {
                console.log("Sending FCM token to the server:", token);

                // API URL from environment variable
                const API_URL = process.env.REACT_APP_API_URL;
                console.log("API URL:", API_URL); // https://ruti.site/api

                fetch(`${API_URL}/user/saveToken`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }),
                    credentials: 'include', 
                }).then(response => {
                    if (response.ok) {
                        console.log('FCM 토큰이 성공적으로 저장되었습니다.');
                    } else {
                        console.error('토큰 저장 중 문제가 발생했습니다.');
                    }
                }).catch(error => {
                    console.error('토큰 저장 오류:', error);
                });
            }
        }).catch(error => {
            console.error('푸시 알림 권한 요청 중 오류 발생:', error);
        });
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/join" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/join/email" element={<SignUpEmail />} />
                <Route path="/user/change-name" element={<Nickname />} />
                <Route path="/user/change-pw/*" element={<Password />} />
                <Route path="/user/delete" element={<Delete />} />
                <Route path="/user/logout" element={<Logout />} />
                <Route path="/user/QA" element={<QA />} />
                <Route path="/home" element={<Home />} />
                <Route path="/memo" element={<Memo />} />
                <Route path="/routine/*" element={<Routine />} />
                <Route path="/routine/new/*" element={<NewRoutine />} />
                <Route path="/routine/all" element={<AllRoutines />} />
                <Route path="/routine/edit/:id/*" element={<EditRoutine />} />
                <Route path="/home/todo/:categoryID" element={<TodoAdd />} />
                <Route path="/my" element={<My />} />
                <Route path="/home/category-add" element={<CategoryAdd />} />
                <Route
                    path="/*"
                    element={<Base toggleMenu={toggleMenu} menuOpen={menuOpen} />}
                />
            </Routes>
        </Router>
    );
};

export default App;
