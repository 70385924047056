import React from 'react';
import './deleteModal.css';

const DeleteModal = ({ isOpen, onClose, onDelete, message }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="delete-confirm">
        <p className="delete-confirm-text">{message}</p>
        <div className="delete-confirm-buttons">
          <button className="modal-button" onClick={onClose}>취소</button>
          <button className="modal-button" onClick={onDelete}>삭제</button>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
