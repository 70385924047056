import React from 'react';
import './button.css';

const Button = ({ text, icon, onClick }) => {
  return (
    <button className="basic-button" onClick={onClick}>
      {icon && <img src={icon} alt="" className="button-icon" />}
      <span className="button-text">{text}</span>
    </button>
  );
};

export default Button;