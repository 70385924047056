import React, { useState } from 'react';
import './newRoutine.css';
import Base from '../base';
import Input from '../components/input';
import Button from '../components/button';
import DatePicker, { registerLocale } from 'react-datepicker';
import TimePicker from 'react-time-picker';
import { ko } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

registerLocale('ko', ko);

const NewRoutine = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [menuOpen, setMenuOpen] = useState(false);
    const [routineTitle, setRoutineTitle] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [time, setTime] = useState('10:00');
    const [activeDays, setActiveDays] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // KST에서 UTC로 변환하는 함수
    const convertToUTC = (date) => {
        const utcDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        return utcDate.toISOString().split('T')[0];
    };

    const handleDayClick = (day) => {
        setActiveDays((prev) =>
          prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };

    const handleSubmit = async () => {
        if (!routineTitle || !startDate || !endDate || activeDays.length === 0) {
            setErrorMessage('모든 필드를 작성해주세요.');
            return;
        }
    
        if (new Date(endDate) < new Date(startDate)) {
            setErrorMessage('종료 날짜는 시작 날짜보다 이후여야 합니다.');
            return;
        }
    
        const routineData = {
            title: routineTitle,
            // UTC로 변환하여 서버로 전달
            startDate: convertToUTC(startDate),
            endDate: convertToUTC(endDate),
            time,
            activeDays: activeDays.join(', ')
        };
    
        // 저장되는 날짜와 시간 출력
        console.log("루틴 저장 데이터:", {
            title: routineTitle,
            startDate: convertToUTC(startDate),
            endDate: convertToUTC(endDate),
            time,
            activeDays: activeDays.join(', ')
        });
    
        try {
            const response = await axios.post(`${API_URL}/routine/new`, routineData, {
                headers: { 
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
    
            if (response.status === 201) {
                setErrorMessage('루틴이 성공적으로 등록되었습니다.');
                navigate('/routine');
            } else {
                setErrorMessage('루틴 등록에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error adding routine:', error);
            setErrorMessage('루틴 등록 중 오류가 발생했습니다.');
        }
    };
    

    return (
        <div className="newRoutine-container">
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <div className="newRoutine-content">
                <h1 className="newRoutine-title">ROUTINE</h1>
                <h2 className="newRoutine-subtitle">루틴 등록</h2>
                <div className="newRoutine-inputs">
                    <Input
                        type="text"
                        placeholder="새로운 루틴 명칭을 입력하세요"
                        value={routineTitle}
                        onChange={(e) => setRoutineTitle(e.target.value)}
                    />
                    <DatePicker
                        locale={ko}
                        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
                        className="custom-datepicker"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy년 MM월 dd일"
                        placeholderText="시작 날짜를 선택하세요"
                    />
                    <DatePicker
                        locale={ko}
                        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
                        className="custom-datepicker"                   
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy년 MM월 dd일"
                        placeholderText="종료 날짜를 선택하세요"
                    />
                    <p>루틴 시작 시간</p>
                    <TimePicker
                        onChange={setTime}
                        value={time}
                        disableClock={true}
                        className="custom-timepicker"
                    />
                    <p>반복 요일</p>
                    <div className="days">
                        {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
                            <span
                                key={day}
                                className={activeDays.includes(day) ? 'active' : ''}
                                onClick={() => handleDayClick(day)}
                            >
                                {day}
                            </span>
                        ))}
                    </div>
                    <Button className="newRoutine-button" onClick={handleSubmit} text="작성 완료"> </Button>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>
        </div>
    );
};

export default NewRoutine;
