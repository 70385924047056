import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './hamburger.css';

const Hamburger = ({ menuOpen, toggleMenu }) => {
    const [nickname, setNickname] = useState('USER');
    const navigate = useNavigate();

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchNickname = async () => {
            try {
                const response = await axios.get(`${API_URL}/user/current-nickname`, { withCredentials: true });
                if (response.data && response.data.nickname) {
                    setNickname(response.data.nickname);
                }
            } catch (error) {
                console.error('Error fetching nickname:', error);
            }
        };

        fetchNickname();
    }, []);

    const goToChangeName = () => {
        navigate('/user/change-name');
    };
    const goToChangePw = () => {
        navigate('/user/change-pw');
    };
    const goToQA = () => {
        navigate('/user/QA');
    };
    const goToDelete = () => {
        navigate('/user/delete');
    };
    const goToLogout = () => {
        navigate('/user/logout');
    };

    return (
        <div>
            {/* 백드롭은 menuOpen 상태에 따라 표시 */}
            {menuOpen && <div className="backdrop" onClick={toggleMenu}></div>}
            {/* user-menu는 항상 렌더링하고, menuOpen 상태에 따라 슬라이드 클래스를 추가 */}
            <div className={`user-menu ${menuOpen ? 'slide-in' : 'slide-out'}`}>
                <div className="user-info-ham">
                    <img src="/img/user.png" alt="user" className="user-avatar" />
                    <div className="user-name">{nickname}</div> {/* 닉네임 표시 */}
                </div>
                <div className="menu-items">
                    <div className="menu-item" onClick={goToChangeName}>닉네임 변경</div>
                    <div className="menu-item" onClick={goToChangePw}>비밀번호 변경</div>
                    <div className="menu-item" onClick={goToQA}>1:1 문의</div>
                    <div className="menu-item" onClick={goToLogout}>로그아웃</div>
                    <div className="menu-item" onClick={goToDelete}>회원탈퇴</div>
                </div>
            </div>
        </div>
    );
};

export default Hamburger;
