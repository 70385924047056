import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import Button from '../components/button';
import Input from '../components/input';
import axios from 'axios';


const Login = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const res = await axios.post(`${API_URL}/user/login`, {
        userEmail: email,
        userPW: password
      }, {
        headers: { 
          'Content-Type': 'application/json'
        },
        withCredentials: true // 세션 쿠키를 포함하도록 설정
      });
  
      if (res.status === 200) {
        navigate('/home'); // 로그인 성공 후 
      } else {
        setLoginError('로그인에 실패했습니다.');
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setLoginError('이메일 또는 비밀번호가 올바르지 않습니다.');
      } else {
        console.error('Login ERROR: ', err);
        setLoginError('서버와의 통신 중 오류가 발생했습니다.');
      }
    }
  };  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin(); // 엔터를 누르면 로그인 함수 호출
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <img src="/img/ruti.png" alt="RUTI" className="login-logo" />
        <h1 className="login-title">RUTI</h1>
        <h2 className="login-subtitle">로그인</h2>
        <p className="login-description">RUTI와 함께 나만의 루틴과<br></br> 할일을 관리해보세요!</p>
        
        <div className="login-inputs">
          <Input 
            placeholder="이메일" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            onKeyDown={handleKeyDown}
          />
          <Input 
            placeholder="비밀번호" 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            onKeyDown={handleKeyDown}
          />
        </div>
        {loginError && <p className="login-error-message">{loginError}</p>}

        <div className="login-button">
            <Button text="로그인" onClick={handleLogin}/>
        </div>
        <p className="login-footer">
          계정이 없으신가요? <a href="/join/email">회원가입</a>
        </p>
      </div>
    </div>
  );
};

export default Login;