import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import './signUp.css';
import Button from '../components/button';

const SignUp = () => {

  const navigate = useNavigate();
  const goToSignUpEmail = () => {
    navigate('/join/email');
  };

  // const handleGoogleLoginSuccess = (response) => {
  //   console.log(response);
  //   // Google OAuth 토큰을 백엔드로 보내기
  //   fetch('http://localhost:8080/user/oauth2/callback', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ token: response.credential }),
  //   })
  //   .then(response => response.json())
  //   .then(data => {
  //     if (data.success) {
  //       navigate('/home');
  //     } else {
  //       console.error('Google login failed:', data.message);
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error during Google login:', error);
  //   });
  // };

  // const handleGoogleLoginFailure = (response) => {
  //   console.error('Google login failed:', response);
  // };

  return (
    <div className="signup-container">
      <div className="signup-content">
        <img src="/img/ruti.png" alt="RUTI" className="signup-logo" />
        <h1 className="signup-title">RUTI</h1>
        <h2 className="signup-subtitle">회원가입</h2>
        <div className="signup-description">RUTI와 함께 나만의 루틴과<br></br> 할일을 관리해보세요!</div>
        
        <div className="signup-buttons">
          <Button text="Email로 시작하기" icon="/img/email.png" onClick={goToSignUpEmail} />
          <Button text="Google로 시작하기" icon="/img/google.png"/>
        </div>

        <p className="signup-footer">
          이미 계정이 있으신가요? <a href="/login">로그인</a>
        </p>
      </div>
    </div>
  );
};

export default SignUp;