import React, { useState } from 'react';
import './dropdown.css';

const Dropdown = ({ selected, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const options = [
        '서비스 오류',
        '추가 개선사항',
        '협력 및 파트너십',
        '기타'
    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        onChange(option);
        setIsOpen(false);
    };

    return (
        <div className="dropdown">
            <div 
                className={`dropdown-toggle ${isOpen ? 'open' : ''}`} 
                onClick={toggleDropdown}
            >
                <span className={`selected-option ${selected !== '카테고리 선택' ? 'selected' : ''}`}>
                    {selected}
                </span>
                <img 
                    src={isOpen ? "/img/dropdown-up.png" : "/img/dropdown-down.png"} 
                    alt="toggle" 
                    className="icon" 
                />
            </div>
            {isOpen && (
                <div className="dropdown-menu">
                    {options.map((option, index) => (
                        <div 
                            key={index} 
                            className="dropdown-item" 
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;