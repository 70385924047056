import React, { useState, useEffect } from 'react';
import './routine.css';
import Base from '../base';
import { useNavigate } from 'react-router-dom';
import WeekCalendar from './weekCalendar';
import axios from 'axios';

const Routine = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [routines, setRoutines] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [menuOpen, setMenuOpen] = useState(false);
  const [nickname, setNickname] = useState('USER');
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // UTC로 변환하는 함수
  const convertToUTC = (date) => {
    // 시간을 빼기 전에 UTC 자정으로 설정
    const utcDate = new Date(date.getTime());
    utcDate.setUTCHours(0, 0, 0, 0); // 시간을 자정으로 설정하여 날짜만 유지
    return utcDate;
  };

  // KST로 변환하는 함수
  const convertToKST = (date) => {
    const kstDate = new Date(date);
    kstDate.setUTCHours(date.getUTCHours() + 9); 
    return kstDate;
  };

  useEffect(() => {
    const fetchNickname = async () => {
      try {
        const response = await axios.get(`${API_URL}/user/current-nickname`, { withCredentials: true });
        if (response.data && response.data.nickname) {
          setNickname(response.data.nickname);
        }
      } catch (error) {
        console.error('Error fetching nickname:', error);
      }
    };

    fetchNickname();
  }, []);

  useEffect(() => {
    const fetchRoutines = async () => {
      try {
        const response = await axios.get(`${API_URL}/routine`, { withCredentials: true });
        const routinesWithKST = response.data.map(routine => ({
          ...routine,
          startDate: convertToKST(new Date(routine.startDate)),
          endDate: convertToKST(new Date(routine.endDate)),
          // statesByDate가 없으면 초기화
          statesByDate: routine.statesByDate || {}
        }));
        setRoutines(routinesWithKST);
      } catch (error) {
        console.error('Error fetching routines:', error);
      }
    };
    fetchRoutines();
  }, []); 

  useEffect(() => {
    const updateCurrentRoutine = async () => {
      const today = new Date();
      const todayStr = today.toISOString().split('T')[0]; 
      const nowTime = today.getHours() * 60 + today.getMinutes();

      const updatedRoutines = routines.map(routine => {
        const [hours, minutes] = routine.time.split(':').map(Number);
        const routineTime = hours * 60 + minutes;
        const routineStartDate = new Date(routine.startDate);
        const routineEndDate = new Date(routine.endDate);

        const isWithinRoutineDates = routineStartDate <= today && routineEndDate >= today;
        const isTodayRoutine = routineStartDate.toISOString().split('T')[0] === todayStr;
  
        if (routineStartDate > today) {
          return {
            ...routine,
            state: 'pending',
            past: false, 
          };
        }

        if (isWithinRoutineDates && isTodayRoutine && routineTime < nowTime && routine.state === 'pending') {
          return {
            ...routine,
            state: 'missed',
            past: true,
          };
        }

        // 현재 날짜의 루틴 중 현재 시간에 해당하는 루틴 표시
        return {
          ...routine,
          past: routineTime < nowTime
        };
      });

      setRoutines(prevRoutines => {
        if (JSON.stringify(prevRoutines) !== JSON.stringify(updatedRoutines)) {
          return updatedRoutines;
        }
        return prevRoutines;
      });

      const routinesToUpdate = updatedRoutines.filter(
        routine => routine.state === 'missed' && new Date(routine.startDate).toISOString().split('T')[0] === todayStr
      );
      for (const routine of routinesToUpdate) {
        // 서버로 보낼 때 UTC로 변환
        const utcStartDate = convertToUTC(new Date(routine.startDate));
        await axios.put(`${API_URL}/routine/updateState/${routine.id}/${utcStartDate.toISOString().split('T')[0]}`, {
          state: routine.state
        }, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });
      }
    };

    updateCurrentRoutine();
    const interval = setInterval(updateCurrentRoutine, 60000); // 1분마다 현재 루틴 업데이트

    return () => clearInterval(interval);
  }, [routines]); // routines 배열을 의존성 배열로 설정

  const handleCheck = async (id, date) => {
    try {
      // 해당 루틴의 선택된 날짜에 해당하는 상태를 업데이트
      const updatedRoutine = routines.find(routine => routine.id === id);
      if (!updatedRoutine) return;
  
      // 날짜별 상태 정보가 없으면 초기화
      if (!updatedRoutine.statesByDate) {
        updatedRoutine.statesByDate = {};
      }
  
      const currentDateState = updatedRoutine.statesByDate[date] || 'pending'; 
      const newState = currentDateState === 'pending' ? 'done' :
                       currentDateState === 'done' ? 'missed' :
                       'pending';  // 상태 변경 로직
  
      // 해당 날짜의 상태 업데이트
      updatedRoutine.statesByDate[date] = newState;
  
      // 전체 루틴 리스트에서 해당 루틴만 업데이트
      const updatedRoutines = routines.map(routine =>
        routine.id === id ? { ...routine, statesByDate: updatedRoutine.statesByDate } : routine
      );
  
      // 선택한 날짜를 UTC로 변환하여 서버로 전송
      const utcDate = convertToUTC(new Date(date));
      await axios.put(`${API_URL}/routine/updateState/${id}/${utcDate.toISOString().split('T')[0]}`, { state: newState }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });
  
      setRoutines(updatedRoutines); // 업데이트된 루틴 목록을 다시 설정
    } catch (error) {
      console.error('Error updating routine status:', error);
    }
  };

  
  const handleRoutineClick = (id) => {
    navigate(`/routine/edit/${id}`);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'done':
        return '/img/routineComplete.png';
      case 'missed':
        return '/img/routineUncomplete.png';
      case 'pending':
      default:
        return '/img/routineCheck.png';
    }
  };

  const handleAddRoutine = () => {
    navigate('/routine/new');
  };

  const handleAllRoutines = () => {
    navigate('/routine/all');
  };

  const filterRoutines = () => {
    const today = selectedDate;
    const dayOfWeek = today.toLocaleString('ko-KR', { weekday: 'short' });
    
    return routines.filter(routine => {
      const routineDays = routine.activeDays.split(', ');
      const routineStartDate = new Date(routine.startDate);
      const routineEndDate = new Date(routine.endDate);
  
      // 선택한 날짜가 루틴의 기간 내에 있으며, 선택한 요일에 포함된 루틴만 필터링
      const isActiveDay = routineDays.includes(dayOfWeek);
      const isWithinDateRange = today >= routineStartDate && today <= routineEndDate;
  
      return isActiveDay && isWithinDateRange;
    }).sort((a, b) => {
      const [hoursA, minutesA] = a.time.split(':').map(Number);
      const [hoursB, minutesB] = b.time.split(':').map(Number);
      return hoursA * 60 + minutesA - (hoursB * 60 + minutesB);
    });
  };
  

  const filteredRoutines = filterRoutines();

  return (
    <div className="routine-page">
      <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
      <header className="routine-header">
        <div className="header-content">
          <div className="header-text">
            {/* 닉네임을 동적으로 표시 */}
            <h1>{nickname} 님, </h1>
            <h2> 오늘의 루틴을 완수하세요! </h2>
          </div>
          <button className="all-routines-button" onClick={handleAllRoutines}>
            <img src="/img/allRoutines.png" alt="all Icon" className="all-icon"/>
          </button>
          <WeekCalendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </div>
      </header>

      <div className="routine-list">
        {filteredRoutines.length > 0 ? (
          filteredRoutines.map((routine) => {
            const isCurrentRoutine = selectedDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0] &&
                                    routine.time === `${new Date().getHours()}:${new Date().getMinutes()}`;

            const routineStatesByDate = routine.statesByDate || {};
            const currentDateState = routineStatesByDate[selectedDate.toISOString().split('T')[0]] || 'pending';

            return (
              <div
                key={routine.id}
                className={`routine-item ${isCurrentRoutine ? 'current' : ''} ${routine.past ? 'past' : ''}`}
                onClick={() => handleRoutineClick(routine.id)} // 클릭 시 수정 페이지로 이동
              >
                <div className="routine-details">
                  <h2>{routine.title}</h2>
                  <span>{routine.time}</span>
                </div>
                <button
                  className={`status-button ${currentDateState}`}
                  onClick={(e) => { e.stopPropagation(); handleCheck(routine.id, selectedDate.toISOString().split('T')[0]); }}
                >
                  <img src={getStatusIcon(currentDateState)} alt={currentDateState} />
                </button>
              </div>
            );
          })
        ) : (
          
          <div className="no-routines-message">
            오늘의 루틴이 없습니다 <br />
            <img src="/img/ruti.png" alt="logo" className="no-routines-logo" /> 

          </div>
        )}
      </div>
      <button className="add-routine-button" onClick={handleAddRoutine}>
        <img src="/img/addMemo.png" alt="Add Icon" className="add-icon" />
      </button>
    </div>
  );
};

export default Routine;
