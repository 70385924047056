import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import './signUp.css';
import Button from '../components/button';
import Input from '../components/input';
import InputCheck from '../components/inputCheck';
import axios from 'axios';

const SignUpEmail = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [nicknameError, setNicknameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [formError, setFormError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return regex.test(password);
  };

  const checkNickname = async () => {
    if (nickname.length < 3) {
      setNicknameError('닉네임은 3글자 이상이어야 합니다.');
      return;
    }

    try {
      const res = await axios.get(`${API_URL}/user/check-nickname`, {
        params: { nickname: nickname }
      });
      if (res.data) {
        setNicknameError('닉네임이 이미 사용 중입니다.');
      } else {
        setNicknameError('사용 가능한 닉네임입니다.');
      }
    } catch (err) {
      console.error('Nickname check ERROR: ', err);
      setNicknameError('서버와의 통신 중 오류가 발생했습니다.');
    }
  };

  const checkEmail = async () => {
    if (!validateEmail(email)) {
      setEmailError('유효한 이메일 주소를 입력해주세요.');
      return;
    }

    try {
      const res = await axios.get(`${API_URL}/user/check-email`, {
        params: { email: email }
      });
      if (res.data) {
        setEmailError('이메일이 이미 사용 중입니다.');
      } else {
        setEmailError('사용 가능한 이메일입니다.');
      }
    } catch (err) {
      console.error('Email check ERROR: ', err);
      setEmailError('서버와의 통신 중 오류가 발생했습니다.');
    }
  };

  const handleSignUp = async () => {
    setFormError('');

    if (!nickname || !email || !password || !passwordConfirm) {
      alert('모든 필드를 입력해주세요.');
      return;
    }

    if (nickname.length < 3) {
      setFormError('닉네임은 3글자 이상이어야 합니다.');
      return;
    }

    if (!validateEmail(email)) {
      setFormError('유효한 이메일 주소를 입력해주세요.');
      return;
    }

    if (!validatePassword(password)) {
      setFormError('비밀번호는 영문, 숫자, 특수문자를 포함한 8자이상이어야 합니다.');
      return;
    }

    if (password !== passwordConfirm) {
      alert('비밀번호와 비밀번호 확인이 일치하지 않습니다.');
      return;
    }

    try {
      const res = await axios.post(`${API_URL}/user/join`, {
        userEmail: email,
        nickname: nickname,
        userPW: password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (res.status === 201) {
        alert('회원가입에 성공했습니다.');
        navigate('/login')
      } else {
        alert('회원가입에 실패했습니다.');
      }
    } catch (err) {
      if (err.response && err.response.status === 409) {
        alert(err.response.data);
      } else {
        console.error('Sign up ERROR: ', err);
        alert('서버와의 통신 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="signup-container">
      <div className="signupemail-content">
        <img src="/img/ruti.png" alt="RUTI" className="signup-logo" />
        <h1 className="signup-title">RUTI</h1>
        <h2 className="signup-subtitle">회원가입</h2>
        <div className="signup-description">RUTI와 함께 나만의 루틴과<br /> 할일을 관리해보세요!</div>
        
        <div className="signup-buttons">
        <InputCheck 
            placeholder={"닉네임"} 
            value={nickname} 
            onChange={(e) => setNickname(e.target.value)} 
            onClick={checkNickname} 
          />
          {nicknameError && <p className="error-message">{nicknameError}</p>}
          
          <InputCheck 
            placeholder={"이메일"} 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            onClick={checkEmail} 
          />
          {emailError && <p className="error-message">{emailError}</p>}
          
          <Input 
            placeholder={"비밀번호"} 
            type="password" 
            value={password} 
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError('');
            }} 
          />
          <Input 
            placeholder={"비밀번호 확인"} 
            type="password" 
            value={passwordConfirm} 
            onChange={(e) => setPasswordConfirm(e.target.value)} 
          />   
        {formError && <p className="error-message">{formError}</p>}
       
         <Button text="회원가입" onClick={handleSignUp} />
        </div>

        <p className="signup-footer">
          이미 계정이 있으신가요? <a href="/login">로그인</a>
        </p>
      </div>
    </div>
  );
};

export default SignUpEmail;