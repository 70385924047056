import React, { useState } from 'react';
import './home.css';
import Base from '../base';
import CustomCalendar from './customCalendar';
import TodoList from './todoList'

const Home = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleDateChange = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate()+1);
        setSelectedDate(newDate);
    };

    return (
        <div>
            <div className="home-content">
                <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
                <CustomCalendar selectedDate={selectedDate} setSelectedDate={handleDateChange} />
                <TodoList selectedDate={selectedDate} />
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
};

export default Home;
