import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './user.css';

const User = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [nickname, setNickname] = useState('USER');

    useEffect(() => {
        const fetchNickname = async () => {
            try {
                const response = await axios.get(`${API_URL}/user/current-nickname`, { withCredentials: true });
                if (response.data && response.data.nickname) {
                    setNickname(response.data.nickname);
                }
            } catch (error) {
                console.error('Error fetching nickname:', error);
            }
        };

        fetchNickname();
    }, []);

    return (
        <div className="user-info-hamburger">
            <img src="/img/user.png" alt="User" className="user-avatar-hamburger" />
            <p className="user-name-hamburger nickname-bold">{nickname}</p> {}
        </div>
    );
};

export default User;
