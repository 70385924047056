import React, { useState } from 'react';
import './category-add.css';
import Button from '../components/button';
import Input from '../components/input';
import Base from '../base';
import './home.css';
import InputShort from '../components/inputShort';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CategoryAdd = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [menuOpen, setMenuOpen] = useState(false);
    const [category, setCategory] = useState('');
    const [color, setColor] = useState({ hex: '#000000', rgb: { r: 0, g: 0, b: 0 } });
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setErrorMessage(''); // 입력 시 에러 메시지 초기화
    };

    const handleColorChange = (color) => {
        setColor(color);
    };

    const toggleColorPicker = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleAddCategory = async () => {
        if (!category) {
            setErrorMessage('카테고리를 입력해주세요.');
            return;
        }

        const categoryData = {
            category,
            categoryColor: color.hex.toUpperCase()
        };

        try {
            const response = await axios.post(`${API_URL}/home/category-add`, categoryData, {
                withCredentials: true, // 쿠키를 전송하기 위해 설정
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 201) {
                navigate('/home');
            }
        } catch (error) {
            console.error("There was an error adding the category!", error);
            if (error.response && error.response.status === 401) {
                setErrorMessage("인증에 실패했습니다. 접근 권한을 확인하세요.");
            } else {
                setErrorMessage("카테고리 추가 중 오류가 발생했습니다.");
            }
        }
    };

    const hexColor = color.hex.toUpperCase();

    const buttonStyle = {
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: hexColor,
        borderWidth: "2px",
        borderColor: "#d9d9d9",
        cursor: 'pointer',
        marginLeft: '5px'
    };

    return (
        <div className="categoryAdd-container">
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <div className="categoryAdd-content">
                <h1 className="categoryAdd-title">TO-DO</h1>
                <h2 className="categoryAdd-subtitle">카테고리 추가</h2>
                
                <div className="categoryAdd-inputs">
                    <Input placeholder="카테고리를 입력하세요" value={category} onChange={handleCategoryChange} />
                    <div className="color-picker-container">
                        <InputShort placeholder="색상을 지정하세요" value={hexColor} readOnly />
                        <button className="color-picker-button" onClick={toggleColorPicker} style={buttonStyle}></button>
                        {displayColorPicker && (
                            <div className="popover">
                                <div className="cover" onClick={toggleColorPicker}></div>
                                <SketchPicker
                                    color={color.rgb ? color.rgb : color}
                                    onChange={handleColorChange}
                                    disableAlpha
                                    presetColors={[]}
                                    styles={{
                                        default: {
                                            picker: {
                                                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                                                borderRadius: '10px',
                                            },
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>} {/* 에러 메시지 표시 */}
                <div className="categoryAdd-button">
                    <Button text="작성 완료" onClick={handleAddCategory} />
                </div>
            </div>
        </div>
    );
};

export default CategoryAdd;
