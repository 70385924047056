import React, { useState, useEffect } from 'react';
import './allRoutines.css';
import { useNavigate } from 'react-router-dom';
import Base from '../base';
import axios from 'axios';

const AllRoutines = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [menuOpen, setMenuOpen] = useState(false);
  const [routines, setRoutines] = useState([]); // 백엔드에서 가져온 루틴 데이터를 저장할 상태
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [routineToDelete, setRoutineToDelete] = useState(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const fetchRoutines = async () => {
    try {
      const response = await axios.get(`${API_URL}/routine`, { withCredentials: true });
      console.log(response.data);  // 서버에서 받은 루틴 데이터를 로그로 확인

      setRoutines(response.data);
    } catch (error) {
      console.error('Error fetching routines:', error);
    }
  };

  useEffect(() => {
    fetchRoutines(); // 컴포넌트가 마운트될 때 루틴 데이터를 가져옴
  }, []);

  const handleAddRoutine = () => {
    navigate('/routine/new');
  };

  const handleEditRoutine = (id) => {
    navigate(`/routine/edit/${id}`);
  };

  const openDeleteConfirm = (routine) => {
    setRoutineToDelete(routine);
    setIsDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setRoutineToDelete(null);
    setIsDeleteConfirmOpen(false);
  };

  const handleDelete = async () => {
    if (routineToDelete) {
      try {
        await axios.delete(`${API_URL}/routine/delete/${routineToDelete.id}`, { withCredentials: true });
        console.log(`${routineToDelete.title} 삭제됨`);
        setRoutines(prevRoutines => prevRoutines.filter(r => r.id !== routineToDelete.id));
        closeDeleteConfirm();
      } catch (error) {
        console.error('Error deleting routine:', error);
      }
    }
  };

  const sortActiveDays = (daysString) => {
    const dayOrder = ['월', '화', '수', '목', '금', '토', '일'];
    const daysArray = daysString.split(',').map(day => day.trim());
    return daysArray.sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));
  };

  return (
    <div className="allRoutine-container">
      <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
      <div className="allRoutine-content">
        <header className="allRoutine-title">
          All Routines
        </header>
        
        <div className="all-routines-list">
          {routines.map((routine) => (
            <div key={routine.id} className="routine-card">
              <div className="routine-details">
                <h2>{routine.title}</h2>
                <span>
                  {routine.time} - 
                  {routine.activeDays && sortActiveDays(routine.activeDays).join(', ')}
                </span>
              </div>
              <div className="routine-buttons">
                <button className="routine-button" onClick={() => handleEditRoutine(routine.id)}>수정</button>
                <button className="routine-button" onClick={() => openDeleteConfirm(routine)}>삭제</button>
              </div>          
            </div>
          ))}
        </div>
        
        <button className="add-routine-button" onClick={handleAddRoutine}>
          <img src="/img/addMemo.png" alt="Add Icon" className="add-icon" />
        </button>

        {isDeleteConfirmOpen && (
          <>
            <div className="modal-overlay" onClick={closeDeleteConfirm}></div>
            <div className="delete-confirm">
              <p className="delete-confirm-text">루틴을 삭제하시겠습니까?</p>
              <div className="delete-confirm-buttons">
                <button className="modal-button" onClick={closeDeleteConfirm}>취소</button>
                <button className="modal-button" onClick={handleDelete}>삭제</button>
              </div>
            </div>
          </>
        )}
        
      </div>
    </div>
  );
};

export default AllRoutines;