import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../components/input';
import Button from '../components/button';
import Base from '../base';
import User from './user';
import './change.css';
import axios from 'axios';
import InputCheck from '../components/inputCheck';

const Password = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [message, setMessage] = useState('');
    const [isPasswordVerified, setIsPasswordVerified] = useState(false); // 비밀번호 확인 여부
    const [menuOpen, setMenuOpen] = useState(false);

    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\-_]).{8,}$/;
        return regex.test(password);
    };

    const handleCheckPassword = async () => {
        try {
            const res = await axios.post(`${API_URL}/user/check-pw`, {
                currentPassword: currentPassword
            }, {
                headers: { 
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (res.data) {
                setMessage('비밀번호 확인되었습니다.');
                setIsPasswordVerified(true); // 비밀번호 확인 성공
            } else {
                setMessage('기존 비밀번호가 일치하지 않습니다.');
                setIsPasswordVerified(false); // 비밀번호 확인 실패
            }
        } catch (err) {
            console.error('Check password error: ', err);
            setMessage('로그인을 먼저 해주세요');
            setIsPasswordVerified(false); // 비밀번호 확인 실패
        }
    };

    const handleChangePassword = async () => {
        if (!isPasswordVerified) {
            setMessage('기존 비밀번호를 먼저 확인해주세요.');
            return;
        }

        if (newPassword !== newPasswordConfirm) {
            setMessage('새 비밀번호와 비밀번호 확인이 일치하지 않습니다.');
            return;
        }

        if (!validatePassword(newPassword)) {
            setMessage('비밀번호는 영문, 숫자, 특수문자를 포함한 8자 이상이어야 합니다.');
            return;
        }

        try {
            const res = await axios.post(`${API_URL}/user/change-pw`, {
                newPassword: newPassword
            }, {
                headers: { 
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (res.data.success) {
                setMessage('비밀번호가 성공적으로 변경되었습니다.');
                setCurrentPassword('');
                setNewPassword('');
                setNewPasswordConfirm('');
                setIsPasswordVerified(false); // 비밀번호 변경 후 확인 상태 초기화
                navigate('/home'); // 비밀번호 변경 후 홈으로 리다이렉트
            } else {
                setMessage('비밀번호 변경에 실패하였습니다.');
            }
        } catch (err) {
            console.error('Change password error: ', err);
            setMessage('서버와의 통신 중 오류가 발생했습니다.');
        }
    };

    return (
        <div>
            <User />
            <div className="change-title">
                비밀번호 변경
            </div>
            <div className="change-inputs">
                <InputCheck
                    placeholder="기존 비밀번호" 
                    type="password" 
                    value={currentPassword} 
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    onClick={handleCheckPassword} 
                    buttonText="확인"
                />
                <Input 
                    placeholder="새 비밀번호" 
                    type="password" 
                    value={newPassword} 
                    onChange={(e) => setNewPassword(e.target.value)} 
                />
                <Input 
                    placeholder="새 비밀번호 확인" 
                    type="password" 
                    value={newPasswordConfirm} 
                    onChange={(e) => setNewPasswordConfirm(e.target.value)} 
                />
                {message && <p className={`error-message ${message.includes('성공') ? 'success' : 'error'}`}>{message}</p>}
                <Button text="변경하기" onClick={handleChangePassword} />
            </div>
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
        </div>
    );
};

export default Password;