import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/button';
import Base from '../base';
import User from './user';
import './change.css';
import axios from 'axios';


const Logout = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = async () => {
        try {
            const res = await axios.post(`${API_URL}/user/logout`, {}, {
                headers: { 
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (res.status === 200) {
                navigate('/login'); // 로그아웃 후 로그인 페이지로 리다이렉트
            } else {
                alert('로그아웃에 실패했습니다.');
            }
        } catch (err) {
            console.error('Logout error: ', err);
            alert('서버와의 통신 중 오류가 발생했습니다.');
        }
    };

    const handleCancel = () => {
        navigate('/home'); // 취소 버튼 클릭 시 홈으로 리다이렉트
    };

    return (
        <div>
            <User />
            <div className="change-title">
                로그아웃하시겠습니까?
            </div>
            <div className="change-inputs">
                <Button text="취소" onClick={handleCancel}/>
                <Button text="로그아웃" onClick={handleLogout} />
            </div>
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
        </div>
    );

}

export default Logout;