import React, { useState } from 'react';
import Button from '../components/button';
import InputText from '../components/inputText';
import Base from '../base';
import User from './user';
import Dropdown from './dropdown';
import './change.css';
import axios from 'axios';


const QA = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [menuOpen, setMenuOpen] = useState(false);
    const [category, setCategory] = useState('카테고리 선택');
    const [content, setContent] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleDropdownChange = (selected) => {
        setCategory(selected);
    };

    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    const handleSubmit = async () => {
        if (category === '카테고리 선택') {
            setErrorMessage('카테고리를 선택해주세요.');
            return;
        }

        if (content.trim() === '') {
            setErrorMessage('문의 내용을 작성해주세요.');
            return;
        }

        try {
            const res = await axios.post(`${API_URL}/qa`, {
                category: category,
                content: content
            }, {
                headers: { 
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (res.data.success) {
                alert('문의가 성공적으로 접수되었습니다.');
                setCategory('카테고리 선택');
                setContent('');
                setErrorMessage('');
            } else {
                setErrorMessage('문의 접수에 실패하였습니다.');
            }
        } catch (err) {
            console.error('QA submission error: ', err);
            setErrorMessage('서버와의 통신 중 오류가 발생했습니다.');
        }
    };

    return (
        <div>
            <User />
            <div className="change-title">
                문의하기
            </div>
            <div className="change-inputs">
                <Dropdown selected={category} onChange={handleDropdownChange} />
                <InputText 
                    placeholder="문의할 내용을 작성해주세요" 
                    value={content}
                    onChange={handleContentChange} 
                />
                {errorMessage && <p className="error-message">{errorMessage}</p>}                    
                <Button text="작성 완료" onClick={handleSubmit}/>
            </div>
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
        </div>
    );

}

export default QA;