import React, { useState } from 'react';
import './inputCheck.css';

const InputShort = ({ value, placeholder, readOnly }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="input-short-container">
        <input
        type = "text"
        value={value}
        placeholder={placeholder}
        readOnly = {readOnly}
        className={`input-short ${isFocused ? 'input-short-focused' : ''}`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        />
      </div>
  );
};

export default InputShort;