import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../components/input';
import InputCheck from '../components/inputCheck';
import Button from '../components/button';
import Base from '../base';
import User from './user';
import './change.css';

const Nickname = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [currentNickname, setCurrentNickname] = useState('');
    const [newNickname, setNewNickname] = useState('');
    const [nicknameError, setNicknameError] = useState('');

    const API_URL = process.env.REACT_APP_API_URL;

    const navigate=useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        // 사용자 정보를 불러와서 현재 닉네임 설정
        const fetchCurrentNickname = async () => {
            try {
                const response = await fetch(`${API_URL}/user/current-nickname`, {
                    credentials: 'include',
                });
                const result = await response.json();
                setCurrentNickname(result.nickname);
            } catch (error) {
                console.error('Error fetching current nickname:', error);
            }
        };

        fetchCurrentNickname();
    }, []);

    const handleNewNicknameChange = (e) => {
        setNewNickname(e.target.value);
        setNicknameError(''); // 새로운 닉네임을 입력할 때마다 에러 메시지 초기화
    };

    const checkNicknameAvailability = async () => {
        if (newNickname.length < 3) {
            setNicknameError('닉네임은 3자 이상이어야 합니다.');
            return;
        }

        try {
            const response = await fetch(`${API_URL}/user/check-nickname?nickname=${newNickname}`);
            const result = await response.json();
            if (result) {
                setNicknameError('이미 사용 중인 닉네임입니다.');
            } else {
                setNicknameError('사용 가능한 닉네임입니다.');
            }
        } catch (error) {
            console.error('Error checking nickname availability:', error);
        }
    };

    const handleChangeNickname = async () => {
        if (nicknameError !== '사용 가능한 닉네임입니다.') {
            alert('새 닉네임의 중복 확인을 해주세요.');
            return;
        }

        try {
            const response = await fetch(`${API_URL}/user/change-nickname`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ newNickname }),
            });

            if (!response.ok) {
                throw new Error('닉네임 변경에 실패하였습니다.');
            }

            const result = await response.json();
            if (result.success) {
                setCurrentNickname(newNickname);
                setNewNickname('');
                setNicknameError('');
                navigate('/home');
            } else {
                alert('닉네임 변경에 실패하였습니다.');
            }
        } catch (error) {
            console.error('Error changing nickname:', error);
            alert('닉네임 변경에 실패하였습니다.');
        }
    };

    return (
        <div>
            <User />
            <div className="change-title">
                닉네임 변경
            </div>
            <div className="change-inputs">
                <Input placeholder="기존 닉네임" value={currentNickname} disabled />
                <InputCheck
                    placeholder="새 닉네임"
                    value={newNickname}
                    onChange={handleNewNicknameChange}
                    onClick={checkNicknameAvailability}
                />
                {nicknameError && <p className="nickname-error-message">{nicknameError}</p>}
                <Button text="변경하기" onClick={handleChangeNickname} />
            </div>
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
        </div>
    );
}

export default Nickname;