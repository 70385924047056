import React, { useState } from 'react';
import './inputText.css';

const InputText = ({ placeholder, value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <textarea
      placeholder={placeholder}
      className={`input-text ${isFocused ? 'input-text-focused' : ''}`}
      value={value}
      onChange={onChange}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );
};

export default InputText;