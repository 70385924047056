import React, { useState } from 'react';
import './yearMonthDropdown.css';

const YearMonthDropdown = ({ year, month, onYearChange, onMonthChange }) => {
    const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
    const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);

    const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);

    const toggleYearDropdown = () => {
        setIsYearDropdownOpen(!isYearDropdownOpen);
    };

    const toggleMonthDropdown = () => {
        setIsMonthDropdownOpen(!isMonthDropdownOpen);
    };

    const handleYearChange = (year) => {
        onYearChange(year);
        setIsYearDropdownOpen(false);
    };

    const handleMonthChange = (month) => {
        onMonthChange(month);
        setIsMonthDropdownOpen(false);
    };

    return (
        <div className="ym-dropdown-container">
            <div className="ym-dropdown">
                <div 
                    className={`ym-dropdown-toggle ${isYearDropdownOpen ? 'open' : ''}`} 
                    onClick={toggleYearDropdown}
                >
                    <span className={`ym-selected-option ${year ? 'selected' : ''}`}>
                        {year ? `${year}년` : "Year 선택"}
                    </span>
                    <img 
                        src={isYearDropdownOpen ? "/img/dropdown-up.png" : "/img/dropdown-down.png"} 
                        alt="toggle" 
                        className="icon" 
                    />
                </div>
                {isYearDropdownOpen && (
                    <div className="ym-dropdown-menu">
                        {years.map((yr, index) => (
                            <div 
                                key={index} 
                                className="ym-dropdown-item" 
                                onClick={() => handleYearChange(yr)}
                            >
                                {yr}년
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="ym-dropdown">
                <div 
                    className={`ym-dropdown-toggle ${isMonthDropdownOpen ? 'open' : ''}`} 
                    onClick={toggleMonthDropdown}
                >
                    <span className={`ym-selected-option ${month ? 'selected' : ''}`}>
                        {month ? `${month}월` : "Month 선택"}
                    </span>
                    <img 
                        src={isMonthDropdownOpen ? "/img/dropdown-up.png" : "/img/dropdown-down.png"} 
                        alt="toggle" 
                        className="icon" 
                    />
                </div>
                {isMonthDropdownOpen && (
                    <div className="ym-dropdown-menu">
                        {months.map((mth, index) => (
                            <div 
                                key={index} 
                                className="ym-dropdown-item" 
                                onClick={() => handleMonthChange(mth)}
                            >
                                {mth}월
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default YearMonthDropdown;
