import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './bottom.css';

const Bottom = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/home')) {
      setActiveTab('/home');
    } else if (path.startsWith('/routine')) {
      setActiveTab('/routine');
    } else if (path.startsWith('/memo')) {
      setActiveTab('/memo');
    } else if (path.startsWith('/my')) {
      setActiveTab('/my');
    }
  }, [location]);

  return (
    <div className="bottom">
      <NavLink 
        to="/home" 
        className="tab-item"
        onClick={() => setActiveTab('/home')}
      >
        <img 
          src={activeTab === '/home' ? "/img/homeB.png" : "/img/homeG.png"} 
          alt="Home" 
          className="tab-icon" 
        />
        <div className="tab-label" style={{ color: activeTab === '/home' ? 'black' : 'gray' }}>
          HOME
        </div>
      </NavLink>
      <NavLink 
        to="/routine" 
        className="tab-item"
        onClick={() => setActiveTab('/routine')}
      >
        <img 
          src={activeTab === '/routine' ? "/img/routineB.png" : "/img/routineG.png"} 
          alt="Routine" 
          className="tab-icon" 
        />
        <div className="tab-label" style={{ color: activeTab === '/routine' ? 'black' : 'gray' }}>
          ROUTINE
        </div>
      </NavLink>
      <NavLink 
        to="/memo" 
        className="tab-item"
        onClick={() => setActiveTab('/memo')}
      >
        <img 
          src={activeTab === '/memo' ? "/img/memoB.png" : "/img/memoG.png"} 
          alt="Memo" 
          className="tab-icon" 
        />
        <div className="tab-label" style={{ color: activeTab === '/memo' ? 'black' : 'gray' }}>
          MEMO
        </div>
      </NavLink>
      <NavLink 
        to="/my" 
        className="tab-item"
        onClick={() => setActiveTab('/my')}
      >
        <img 
          src={activeTab === '/my' ? "/img/myB.png" : "/img/myG.png"} 
          alt="My" 
          className="tab-icon" 
        />
        <div className="tab-label" style={{ color: activeTab === '/my' ? 'black' : 'gray' }}>
          MY
        </div>
      </NavLink>
    </div>
  );
};

export default Bottom;