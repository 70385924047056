import React from "react";
import './top.css';

function Top({ toggleMenu }) {
    return (
        <div>
            <div className="top">
                <div className="top2">
                    <img src="/img/ruti.png" alt="logo" className="top-img" />
                    <div className="text">RUTI</div>
                    <img 
                        src="/img/hamburger.png" 
                        alt="hamburger" 
                        className="hamburger" 
                        onClick={toggleMenu} 
                    />
                </div>
            </div>
        </div>
    );
}

export default Top;