import React, { useState } from 'react';
import './inputCheck.css';

const InputCheck = ({ placeholder, type = "text", value, onChange, onClick, buttonText = "중복확인" }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="input-duplicate-container">
      <input
        type={type}
        placeholder={placeholder}
        className={`input-duplicate ${isFocused ? 'input-duplicate-focused' : ''}`}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <button className="check-button" onClick={onClick}>
        {buttonText}
      </button>
    </div>
  );
};

export default InputCheck;