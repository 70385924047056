import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './todoList.css';
import DeleteModal from '../components/deleteModal.jsx';

const TodoList = ({ selectedDate }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [todos, setTodos] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);
    const [deleteType, setDeleteType] = useState(null); // '카테고리' or 'To do'
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTodosAndCategories = async () => {
            try {
                // selectedDate를 UTC로 변환하여 ISO 문자열로 가져오기
                const date = new Date(selectedDate.getTime() - (9 * 60 * 60 * 1000)); // KST에서 UTC로 변환
                const formattedDate = date.toISOString().split('T')[0]; // ISO 문자열에서 날짜 부분만 추출

                const [todosResponse, categoriesResponse] = await Promise.all([
                    axios.get(`${API_URL}/home/todo/${formattedDate}`, { withCredentials: true }),
                    axios.get(`${API_URL}/home/categories`, { withCredentials: true })
                ]);

                setTodos(todosResponse.data || []);
                setCategories(categoriesResponse.data || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTodosAndCategories();
    }, [selectedDate]);

    const goToCategoryAdd = () => {
        navigate('/home/category-add');
    };

    const handleAddTodo = (categoryID) => {
        const utcDate = new Date(selectedDate.getTime() - (9 * 60 * 60 * 1000)).toISOString(); // KST를 UTC로 변환
    navigate(`/home/todo/${categoryID}?date=${utcDate.split('T')[0]}`);
    };

    const formattedDate = new Date(selectedDate.getTime() - (9 * 60 * 60 * 1000)).toISOString().split('T')[0]; // UTC로 변환
    const tasks = todos.filter(task => task.date === formattedDate);

    const handleToggleComplete = async (todoId, currentStatus) => {
        try {
            await axios.put(`${API_URL}/home/todo/${todoId}`, {
                completed: !currentStatus
            }, { withCredentials: true });
            const date = new Date(selectedDate.getTime() - (9 * 60 * 60 * 1000)).toISOString().split('T')[0]; // UTC로 변환
            const response = await axios.get(`${API_URL}/home/todo/${date}`, { withCredentials: true });
            setTodos(response.data);
        } catch (error) {
            console.error('Error updating todo:', error);
        }
    };

    const openDeleteConfirm = (target, type) => {
        setDeleteTarget(target);
        setDeleteType(type);
        setIsDeleteConfirmOpen(true);
    };

    const closeDeleteConfirm = () => {
        setDeleteTarget(null);
        setDeleteType(null);
        setIsDeleteConfirmOpen(false);
    };

    const handleDelete = async () => {
        try {
            if (deleteType === 'To do') {
                await axios.delete(`${API_URL}/home/todo/${deleteTarget.id}`, { withCredentials: true });
                setTodos(todos.filter(todo => todo.id !== deleteTarget.id));
            } else if (deleteType === '카테고리') {
                await axios.delete(`${API_URL}/home/category/${deleteTarget.id}`, { withCredentials: true });
                setCategories(categories.filter(cat => cat.id !== deleteTarget.id));
            }
            closeDeleteConfirm();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    return (
        <div className="todo-list">
            <div className="todo-header">
                TO DO
                <img src="/img/addCategory.png" alt="addCategory" className="add-category" onClick={goToCategoryAdd} />
            </div>
            {categories.length > 0 ? (
                <div className="category-list">
                    {categories.map((cat) => (
                        <div key={cat.id} className="todo-category">
                            <div
                                className="category-header"
                                style={{ backgroundColor: cat.categoryColor }}
                                onClick={() => openDeleteConfirm(cat, '카테고리')}
                            >
                                {cat.category}
                                <img
                                    src="/img/add.png"
                                    alt="add"
                                    className="todo-category-addIcon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddTodo(cat.id);
                                    }}
                                />
                            </div>
                            <ul>
                                {tasks
                                    .filter(task => task.categoryId === cat.id)
                                    .map((task, idx) => (
                                        <li key={idx}>
                                            <input
                                                type="checkbox"
                                                checked={task.completed}
                                                onChange={() => handleToggleComplete(task.id, task.completed)}
                                            />
                                            <span onClick={() => openDeleteConfirm(task, 'To do')}>
                                                {task.todoContent}
                                            </span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))}
                </div>
            ) : (
                <p style={{ fontSize: 'smaller', textAlign: 'center' }}>카테고리를 추가해주세요</p>
            )}

            <DeleteModal 
                isOpen={isDeleteConfirmOpen} 
                onClose={closeDeleteConfirm} 
                onDelete={handleDelete} 
                message={`${deleteType}를 삭제하시겠습니까?`}
            />
        </div>
    );
};

export default TodoList;
