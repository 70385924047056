import React, { useState, useEffect } from 'react';
import './weekCalendar.css';

const WeekCalendar = ({ selectedDate, setSelectedDate }) => {
    const [today, setToday] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState([]);

    useEffect(() => {
        setCurrentWeek(generateWeek(today));
    }, [today]);

    // KST로 변환된 현재 시간을 반환하는 함수
    const getKSTDate = (date) => {
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const kstOffset = 9 * 60 * 60000; // UTC+9
        return new Date(utcDate.getTime() + kstOffset);
    };

    // UTC로 변환된 시간을 반환하는 함수
    const getUTCDate = (date) => {
        const kstOffset = 9 * 60 * 60000; // UTC+9
        return new Date(date.getTime() - kstOffset);
    };

    const startOfWeek = (date) => {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1); // 일요일이 첫 날이면 월요일로 조정
        return new Date(date.setDate(diff));
    };

    const generateWeek = (startDate) => {
        const week = [];
        const start = startOfWeek(new Date(startDate));
        for (let i = 0; i < 7; i++) {
            week.push(new Date(start.getTime() + i * 86400000));
        }
        return week;
    };

    const handlePrevWeek = () => {
        setToday(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() - 7);
            return newDate;
        });
    };

    const handleNextWeek = () => {
        setToday(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() + 7);
            return newDate;
        });
    };

    const handleDayClick = (date) => {
        // 클릭한 날짜를 콘솔에 출력 (KST로 변환하여 출력)
        const clickedKSTDate = getKSTDate(date);
        console.log("Clicked date (KST):", clickedKSTDate);
        setSelectedDate(clickedKSTDate);
    };

    const isSameDay = (date1, date2) => {
        return date1 && date2 && date1.getDate() === date2.getDate() &&
               date1.getMonth() === date2.getMonth() &&
               date1.getFullYear() === date2.getFullYear();
    };

    const formatMonthYear = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}.${month}`;
    };

    return (
        <div className="week-calendar">
            <div className="calendar-header">
                <button className="prev-button" onClick={handlePrevWeek}>‹</button>
                <span className="month-year">
                    {formatMonthYear(today)}
                </span>
                <button className="next-button" onClick={handleNextWeek}>›</button>
            </div>
            <div className="weekdays-labels">
                {['월', '화', '수', '목', '금', '토', '일'].map((day, index) => (
                    <div key={index} className="weekday-label">
                        {day}
                    </div>
                ))}
            </div>
            <div className="week-days">
                {currentWeek.map((date, index) => (
                    <div
                        key={index}
                        className={`day ${isSameDay(date, selectedDate) ? 'selected-day' : ''} ${isSameDay(date, new Date()) ? 'today' : ''}`}
                        onClick={() => handleDayClick(date)}
                    >
                        {date.getDate()}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WeekCalendar;
