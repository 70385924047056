import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './test.css';
import Top from './components/top';
import Bottom from './components/bottom';
import Hamburger from './components/hamburger';

const Home = () => <div>Home Page</div>;
const Routine = () => <div>Routine Page</div>;
const Memo = () => <div>Memo Page</div>;
const My = () => <div>My Page</div>;

const Base = ({ toggleMenu, menuOpen }) => (
    <div>
        <div className="container-top">
            <Top toggleMenu={toggleMenu} />
        </div>
        <div className={`content ${menuOpen ? 'menu-open' : ''}`}>
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/routine" element={<Routine />} />
                <Route path="/memo" element={<Memo />} />
                <Route path="/my" element={<My />} />
            </Routes>
            <Hamburger menuOpen={menuOpen} toggleMenu={toggleMenu} />
        </div>
        <div className="container-bottom">
            <Bottom />
        </div>
    </div>
);

export default Base;
