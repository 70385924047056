import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './memo.css';
import Base from '../base';

const Memo = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [currentMemo, setCurrentMemo] = useState(null);
  const [content, setContent] = useState('');
  const [modalBackgroundColor, setModalBackgroundColor] = useState('#DEE9F6');
  const [memos, setMemos] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // 배경색 배열
  const backgroundColors = ['#fde7e8', '#e8f0fd', '#fff4e8', '#eefae8', '#f5e8fd'];

  useEffect(() => {
    fetchMemos();
  }, []);

  const fetchMemos = async () => {
    try {
      const response = await axios.get(`${API_URL}/memo/`, { withCredentials: true });
      setMemos(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error("Failed to fetch memos:", error);
      }
      setMemos([]);
    }
  };

  useEffect(() => {
  }, [memos]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getBackgroundColor = (memoId) => {
    const index = memoId % backgroundColors.length;
    return backgroundColors[index];
  };

  const openModal = (memo) => {
    setCurrentMemo(memo);
    setContent(memo ? memo.memoContent : '');
    setModalBackgroundColor(memo ? getBackgroundColor(memo.id) : '#DEE9F6');
    setIsModalOpen(true);
    setIsDeleteConfirmOpen(false);
    setError('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentMemo(null);
    setContent('');
    setIsDeleteConfirmOpen(false);
    setError('');
  };

  const addMemo = async () => {
    try {
      const response = await axios.post(`${API_URL}/memo/add`, {
        memoContent: content,
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Memo added successfully:', response.data);
      // 상태에 새 메모를 추가합니다.
      setMemos((prevMemos) => [...prevMemos, response.data]);
      closeModal();
    } catch (error) {
      console.error('Error adding memo:', error);
      setError("error");
    }
    console.log("MemoData to be sent:", content);
  };

  const updateMemo = async () => {
    if (!content.trim()) {
      setError('메모 내용을 입력해주세요.');
      return;
    }

    try {
      const response = await axios.put(`${API_URL}/memo/update/${currentMemo.id}`, {
        memoContent: content,
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Memo updated successfully:', response.data);
      // 상태에서 수정된 메모를 찾아 업데이트합니다.
      setMemos((prevMemos) =>
        prevMemos.map((memo) =>
          memo.id === currentMemo.id ? response.data : memo
        )
      );
      // 수정 후 전체 메모 목록으로 돌아가기
      closeModal();
    } catch (error) {
      console.error('Error updating memo:', error);
      setError('메모 수정에 실패했습니다.');
    }
  };

  const openDeleteConfirm = () => {
    setIsDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const deleteMemo = async (id) => {
    try {
      await axios.delete(`${API_URL}/memo/delete/${id}`, {
        withCredentials: true
      });
      console.log('Memo deleted successfully');
      // 상태에서 삭제된 메모를 제거합니다.
      setMemos((prevMemos) => prevMemos.filter(memo => memo.id !== id));
      closeDeleteConfirm();
      closeModal();
    } catch (error) {
      console.error('Error deleting memo:', error);
      setError('메모 삭제에 실패했습니다.');
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // 검색어에 따라 필터링된 메모 목록
  const filteredMemos = memos.filter(memo => 
    memo.memoContent.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="memo-page">
      <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
      <div className="memo-header">
        <h1 className="memo-title">MEMO</h1>
        <div className="memo-search">
          <img src="/img/search.png" alt="Search Icon" className="search-icon" />
          <input
            type="text"
            placeholder="검색"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="memo-content">
        <div className="memo-list">
          {filteredMemos.length > 0 ? (
            filteredMemos.map((memo) => (
              <div
                className="memo-card"
                key={memo.id}
                style={{ backgroundColor: getBackgroundColor(memo.id) }}
                onClick={() => openModal(memo)}
              >
                <div className="memo-card-header">
                  <button className="menu-button">⋮</button>
                </div>
                <ul>
                  {memo.memoContent ? (
                    memo.memoContent.split('\n').slice(0, 2).map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))
                  ) : (
                    <li>No content</li>
                  )}
                  {memo.memoContent.split('\n').length > 2 && <li>...</li>}
                </ul>

              </div>
            ))
          ) : (
            <p style={{ fontSize: 'smaller', textAlign: 'center' }}>검색어에 해당하는 메모가 없습니다</p>
          )}
        </div>
        <button className="add-memo-button" onClick={() => openModal(null)}>
          <img src="/img/addMemo.png" alt="Add Icon" className="add-icon" />
        </button>
      </div>

      {isModalOpen && (
        <>
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal" style={{ backgroundColor: modalBackgroundColor }}>
            <textarea
              className="modal-textarea"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="내용을 입력하세요 ✏️..."
            ></textarea>
            {error && <p className="error-message">{error}</p>}
            <div className="modal-buttons">
              {currentMemo ? (
                <>
                  <button className="modal-button" onClick={openDeleteConfirm}>삭제</button>
                  <button className="modal-button" onClick={updateMemo}>저장</button>
                </>
              ) : (
                <>
                  <button className="modal-button" onClick={closeModal}>취소</button>
                  <button className="modal-button" onClick={addMemo}>완료</button>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {isDeleteConfirmOpen && (
        <>
          <div className="delete-confirm-overlay" onClick={closeDeleteConfirm}></div>
          <div className="delete-confirm">
            <p className="delete-confirm-text">메모를 삭제하시겠습니까?</p>
            <div className="delete-confirm-buttons">
              <button className="modal-button" onClick={closeDeleteConfirm}>취소</button>
              <button className="modal-button" onClick={() => deleteMemo(currentMemo.id)}>삭제</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Memo;
