import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../components/input';
import Button from '../components/button';
import Base from '../base';
import User from './user';
import './change.css';
import axios from 'axios';

const Delete = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const API_URL = process.env.REACT_APP_API_URL;

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleDelete = async () => {
        try {
            const res = await axios.post(`${API_URL}/user/delete`, {
                email: email,
                password: password
            }, {
                headers: { 
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (res.data.success) {
                alert('회원 탈퇴가 완료되었습니다.');
                navigate('/join'); 
            } else {
                setErrorMessage(res.data.message || '회원 탈퇴에 실패하였습니다.');
            }
        } catch (err) {
            console.error('Delete error: ', err);
            setErrorMessage('서버와의 통신 중 오류가 발생했습니다.');
        }
    };

    return (
        <div>
            <User />
            <div className="change-title">
                회원 탈퇴
            </div>
            <div className="change-inputs">
                <Input 
                    placeholder="이메일" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />
                <Input 
                    placeholder="비밀번호" 
                    type='password' 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <Button text="탈퇴하기" onClick={handleDelete} />
            </div>
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
        </div>
    );

}

export default Delete;