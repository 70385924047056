import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import './todo-add.css';
import Button from '../components/button';
import Input from '../components/input';
import Base from '../base';

const TodoAdd = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [menuOpen, setMenuOpen] = useState(false);
    const [todo, setTodo] = useState('');
    const [categoryInfo, setCategoryInfo] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const { categoryID } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const selectedDate = new Date(queryParams.get('date') || new Date().toISOString());

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleInputChange = (e) => {
        setTodo(e.target.value);
        setErrorMessage(''); // 입력 시 에러 메시지 초기화
    };

    const handleAddTodo = async () => {
        if (!todo) {
            setErrorMessage('할 일을 입력해주세요.');
            return;
        }

        try {
            const date = selectedDate.toISOString().split('T')[0];
            await axios.post(`${API_URL}/home/todo/${categoryID}`, {
                date,
                categoryId: categoryID,
                todoContent: todo,
                completed: false
            }, { withCredentials:true });
            navigate('/home');
        } catch (error) {
            console.error('Error adding todo:', error);
            setErrorMessage('할 일 등록 중 오류가 발생했습니다.');
        }
    };

    useEffect(() => {
        const fetchCategoryInfo = async () => {
            try {
                const response = await axios.get(`${API_URL}/home/category/${categoryID}`, { withCredentials:true });
                setCategoryInfo(response.data);
            } catch (error) {
                console.error('Error fetching category info:', error);
            }
        };

        fetchCategoryInfo();
    }, [categoryID]);

    if (!categoryInfo) {
        return <p style={{ fontSize: 'smaller', textAlign: 'center' }}>Loading category information...</p>;
    }

    return (
        <div className="todoAdd-container">
            <Base toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <div className="todoAdd-content">
                <h1 className="todoAdd-title">TO-DO</h1>
                <h2 className="todoAdd-subtitle">할 일 등록</h2>
                <div className="todoAdd-category" style={{ backgroundColor: categoryInfo.categoryColor }}>
                    {categoryInfo.category}
                </div>
                <div className="todoAdd-inputs">
                    <Input placeholder="할 일을 입력하세요" value={todo} onChange={handleInputChange} />
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>} {}
                <div className="todoAdd-button">
                    <Button text="작성 완료" onClick={handleAddTodo} />
                </div>
            </div>
        </div>
    );
};

export default TodoAdd;
