import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDX4KJqvnk7c86g04JXtK9sP24qqAfGCrE",
    authDomain: "ruti-fe604.firebaseapp.com",
    projectId: "ruti-fe604",
    storageBucket: "ruti-fe604.appspot.com",
    messagingSenderId: "963029966732",
    appId: "1:963029966732:web:23d68745356b7a292000a9",
    measurementId: "G-JY2M8E8ZHS"
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

export const requestPermissionAndGetToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
                const token = await getToken(messaging, { vapidKey: "BGNcXiNF0kiTYLiwl8mt_Kl9HUHgMGezIO7i_xYMqvId_SjMHm0oXJekfAoejvk4ZOMzkYj2p87-EnSqpkI6_2U" });
                console.log("FCM Token:", token);
                return token;
            } else {
                console.error("서비스 워커가 활성화되지 않았습니다.");
                return null;
            }
        } else {
            console.error("푸시 알림 권한이 거부되었습니다.");
            return null;
        }
    } catch (error) {
        console.error("푸시 알림 권한 요청 중 오류 발생:", error);
        return null;
    }
};

onMessage(messaging, (payload) => {
    console.log("포그라운드 푸시 알림 수신:", payload);

    if (Notification.permission === "granted" && navigator.serviceWorker.controller) {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
            body: payload.notification.body,
            icon: '/img/ruti.png'
        };

        new Notification(notificationTitle, notificationOptions);
    }
});



if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration().then((registration) => {
        if (!registration) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then((registration) => {
                    console.log('서비스 워커 등록 성공:', registration);

                    if (registration.active) {
                        console.log('서비스 워커가 활성화되었습니다.');
                    } else {
                        registration.addEventListener('statechange', (event) => {
                            if (event.target.state === 'activated') {
                                console.log('서비스 워커가 활성화되었습니다.');
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.error('서비스 워커 등록 실패:', error);
                });
        } else {
            console.log('서비스 워커가 이미 등록되어 있습니다.');
        }
    });
}