import React, { useState } from 'react';
import './input.css';

const Input = ({ placeholder, type = "text", value, onChange, onKeyDown }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}  
      className={`input ${isFocused ? 'input-focused' : ''}`}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );
};

export default Input;